<template>
  <div class="p-3">
    <checkBankruptcyCertificate
      :propsData1="getUserFromId"
      :propsData2="getBankruptcyRecord"
    ></checkBankruptcyCertificate>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import checkBankruptcyCertificate from "../../components/checkBankruptcy/checkBankruptcyCertificate.vue";
import * as encodeDecodeService from "../../services/encodeDecode.service";
export default {
  name: "CheckBankruptcyCertificate",

  components: {
    checkBankruptcyCertificate,
  },
  data() {
    return {
      projectId: encodeDecodeService.cyDecrypt(this.$route.params.projectId),
      userId: encodeDecodeService.cyDecrypt(this.$route.params.userId),
      productId: encodeDecodeService.cyDecrypt(this.$route.params.checkId),
    };
  },
  computed: {
    ...mapGetters(["getBankruptcyRecord", "getUserFromId"]),
  },
  methods: {
    ...mapActions(["fetchBankruptcyRecord", "fetchUserFromId"]),
  },
  created() {
    this.fetchUserFromId(this.userId);
    this.fetchBankruptcyRecord(this.projectId);
  },
};
</script>
